@import 'includes/vars';
@import 'includes/functions';


body {
  background-color: $bg-color;
  color: $primary-text-color;
}

a {
  color: $link-color;
}

:not(pre) > code {
  // background-color: #f0f0f0;
  font-family: monospace;
  word-wrap: break-word;
}

img {
  max-width: 100%;
}

ul {
  list-style: square;
}

hr {
  border: 0;
  border-top: 1px solid lighten($primary-text-color, 70);
}

@import 'includes/footer';
@import 'includes/utility-classes';

@import 'pages/index';
@import 'pages/journal';
@import 'pages/journal-entry';

// @import 'includes/prism-theme';
@import 'includes/prism-theme.overrides';