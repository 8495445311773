@import 'includes/vars';
@import 'includes/functions';

.page--index {

  ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .max-2-col li {
    flex: max(50%, 200px);
  }

  .max-3-col li {
    flex: max(33%, 150px);
  }
}