body {
  background-color: white;
  color: black;
}

a {
  color: auto;
}

:not(pre) > code {
  font-family: monospace;
  word-wrap: break-word;
}

img {
  max-width: 100%;
}

ul {
  list-style: square;
}

hr {
  border: 0;
  border-top: 1px solid #b3b3b3;
}

footer {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
}
footer ul {
  text-align: center;
  margin-bottom: 2rem;
}
footer li {
  display: inline;
  margin: 1rem;
}

html {
  min-height: 100%;
  position: relative;
}

main {
  padding-bottom: 60px;
}

.dotted-border {
  border: 1px dotted gray;
}

.float-right {
  float: right;
}

.float-left {
  float: left;
}

.clearfix::after {
  content: "";
  clear: both;
  display: table;
}

.mt-0 {
  margin-top: 0;
}

.page--index ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.page--index .max-2-col li {
  flex: max(50%, 200px);
}
.page--index .max-3-col li {
  flex: max(33%, 150px);
}

.page--journal ul {
  padding: 0;
  list-style: none;
  overflow-x: hidden;
  position: relative;
  line-height: 3rem;
}
.page--journal ul a,
.page--journal ul span {
  background-color: white;
}
.page--journal ul li {
  margin-bottom: 3rem;
}
.page--journal ul span {
  margin-left: 1rem;
}
.page--journal ul a.entry-link::after,
.page--journal ul span::before {
  content: "..............................................................................................................................................................................................................";
  position: absolute;
  z-index: -1;
  color: gray;
}
.page--journal ul span::before {
  right: 0;
  background-color: white;
}
.page--journal ul a::after {
  left: 0;
}

.journal-entry--date,
.journal-entry--codepen-link {
  font-size: 90%;
  line-height: 1rem;
}

.journal-entry--codepen-link {
  float: right;
}

code[class*=language-],
pre[class*=language-] {
  color: black;
  font-family: monospace;
  direction: ltr;
  text-align: left;
  white-space: pre;
  word-spacing: normal;
  word-break: normal;
  word-wrap: normal;
  line-height: 1.5rem;
  tab-size: 4;
  hyphens: none;
}

pre[class*=language-] {
  position: relative;
  padding: 1rem 1rem 1rem 3rem;
  margin: 1rem 0;
  overflow: auto;
  border-radius: 0;
  counter-reset: line;
}

code[class*=language-] span.highlight-line:before {
  counter-increment: line;
  content: counter(line);
  text-align: right;
  width: 2rem;
  position: absolute;
  left: 0;
  opacity: 0.2;
}

:not(pre) > code[class*=language-],
pre[class*=language-] {
  background: transparent;
}

.token.comment,
.token.doctype {
  font-style: italic;
  opacity: 0.4;
}

.token.operator,
.token.url,
.language-css .token.string,
.style .token.string,
.token.variable,
.token.punctuation {
  opacity: 0.6;
}

.token.property,
.token.tag {
  font-weight: 900;
}

.token.boolean,
.token.number {
  font-weight: normal;
}

.token.selector,
.token.attr-name,
.token.string {
  font-style: italic;
}


.token.attr-value,
.token.function {
  font-weight: 600;
}

.token.keyword {
  opacity: 0.8;
  font-weight: 900;
}

.token.regex,
.token.important {
  font-style: italic;
}