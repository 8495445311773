@import "includes/vars";
@import "includes/functions";

.journal-entry {}

.journal-entry--date,
.journal-entry--codepen-link {
  @extend .journal-entry !optional;
  font-size: 90%;
  line-height: to-rem(2*8);
}

.journal-entry--codepen-link {
  float: right;
}
