@import "includes/vars";
@import "includes/functions";

.page--journal {
  ul {
    padding: 0;
    list-style: none;
    overflow-x: hidden;
    position: relative;

    line-height: to-rem(6 * 8);

    a,
    span {
      background-color: $bg-color;
    }

    li {
      margin-bottom: to-rem(6*$modular-scale-base-px);
    }

    span {
      margin-left: 1rem;
    }

    a.entry-link::after,
    span::before {
      content: "..............................................................................................................................................................................................................";
      position: absolute;
      z-index: -1;
      color: lighten($primary-text-color, 50);
    }
    span::before {
      right: 0;
      background-color: $bg-color;
    }
    a::after {
      left: 0;
    }
  }
}
