@import 'includes/vars';
@import 'includes/functions';

.dotted-border {
    border: 1px dotted lighten($primary-text-color, 50);
}

.float-right {
    float: right;
}

.float-left {
    float: left;
}

.clearfix { 
    &::after {
        content: "";
        clear: both;
        display: table;
    }
}

.mt-0 {
    margin-top: 0;
}